
import { Component, Mixins } from "vue-property-decorator";
import Page from "@/views/Page.vue";
import { AthleteReportModel, AthleteReportModelEx, AthleteReportCategory, AthleteProfileModel, TeamModel, PlayerOnTeam, CoachProfileModel } from "@/models";
import { VuetifyMixin, StringsMixin, BAIconsMixin, CoachRoutingMixin, MyCoachMixin, PaginatedTableMixin, AthleteApiMixin } from "@/mixins";
import { athleteReportApi } from "@/api/AthleteReportApi";
import { DataTableHeader } from 'vuetify';
import { PaginatedResponse, QueryOptions } from '@/../types/interfaces';
import { notificationStore } from "@/store";

@Component({
	components: { Page },
})
export default class CoachAthleteReportsSummary extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, CoachRoutingMixin, MyCoachMixin, PaginatedTableMixin, AthleteApiMixin) {
    search: string = '';
    coachAthleteReports: Array<AthleteReportModelEx> = [];
    isReportsLoading: boolean = false;
    isReportsLoaded: boolean = false;
    athletes: Array<AthleteProfileModel> = [];
    teams: Array<TeamModel> = [];

    mounted() {
        this.tableOptions.sortBy = ['date'];
        this.tableOptions.sortDesc = [true];
    }

    async loadTable() {
        this.isReportsLoading = true;
        this.isReportsLoaded = false;

        try {
            const query = this.TableQuery<AthleteReportModel>(this.search, ['notes','tags']);
            query.$match = {...query.$match, ...{ coachId: this.MyCoachId }};
            const options: QueryOptions = this.TableQueryOptions;
            const response: PaginatedResponse<AthleteReportModel> = await athleteReportApi.queryAll(query, options);
            this.allItemsCount = response.total;
            const coaches: Array<CoachProfileModel> = [this.MyCoachProfile];

            // CCM_TODO: load information in backend
            // const reports = await athleteReportApi.findByCoachId(this.MyCoachId);
            this.coachAthleteReports = await Promise.all(response.docs.map(async (report) => {
                const reportEx = new AthleteReportModelEx().load(report);
                if( this.IsNotEmpty(reportEx) ) {
                    await reportEx.loadAthleteCoach(coaches);
                    await reportEx.loadAthlete(this.athletes, this.teams);
                    const athlete = this.athletes.find(a => a.id === reportEx.athlete?.id )
                    if( this.IsNotEmpty(athlete) ) this.athletes.push(athlete);
                    const team = this.teams.find(t => t.id === reportEx.team?.id )
                    if( this.IsNotEmpty(team) ) this.teams.push(team);
                }
                return reportEx;
            }));
        } catch(e) {
            notificationStore.pushSnackbarError({message: e});
        }

        this.isReportsLoaded = true;
        this.isReportsLoading = false;
    }
    get TableHeaders(): Array<DataTableHeader<any>> {
        return [
            { text: 'Date', value: 'date', sortable: true, },
            { text: 'Athlete', value: 'athleteId', sortable: false, },
            { text: 'Team', value: 'teamId', sortable: true, },
            { text: 'Rating', value: 'rating', sortable: false, width:'20', align:'center' },
            { text: '', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
        ] 
    }
	get PageLoading(): boolean {
        return !this.MyCoachIsReady || this.TableLoading;
    }
    get TableLoading(): boolean {
        return this.isReportsLoading;
    }
    get TotalItems(): number {
        return this.allItemsCount;
    }

    async onNewReport(athleteId: string = undefined, teamId: string = undefined) {
        this.gotoCreateAthleteReport(athleteId, teamId, this.MyCoachId);
    }
    async onOpenReport(athleteReport: AthleteReportModelEx) {
        this.gotoCoachAthleteReport(athleteReport.id);
    }
    async onDeleteReport(athleteReport: AthleteReportModelEx) {
        if( !confirm(`Delete report for ${athleteReport.LastName}? This action cannot be undone`) ) return;
        await athleteReportApi.delete(athleteReport);
        this.updateTable();
    }
}

